$(function() {
    $('#password-generator-button').pGenerator({
        'bind': 'click',
        'passwordElement': '.password-input',
        'displayElement': '#display-password',
        'passwordLength': 16,
        'uppercase': true,
        'lowercase': true,
        'numbers':   true,
        'specialChars': true,
        // 'onPasswordGenerated': function(generatedPassword) {
        //     alert(generatedPassword);
        // }
    });    
});


$(function() {
    $('nav#menu').mmenu({
        extensions  : [ "border-none", "theme-black", "pagedim-black"],
        offCanvas   : {
            zposition   : "front",
            position    : "left"
        },
        searchfield: true,
        counters: true,
        slidingSubmenus: false,
        navbar: false,
        navbars: [{
        position: 'right',
            content: [
                'close'
            ]
        }
        ]
    });
});

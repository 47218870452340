$(function() {
    $('.datepicker').daterangepicker({
        singleDatePicker: true,
        "timePicker24Hour": true,
        showDropdowns: true,
        timePicker: true,
        locale: {
            format: 'YYYY-MM-DD HH:mm:ss'
        }
    });

    $('.single-date-picker').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true
    }, 
    function(start) {
        start = moment();
    });

    $('.single-time-picker').timepicker();    
});

$('.collapse').collapse('hide');
$(".btnPrint").printPage();
$('[data-toggle="tooltip"]').tooltip();
function toggleChevron(e) {
    $(e.target)
        .prev('.panel-heading')
        .find("i.indicator")
        .toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
}
$('#accordion').on('hidden.bs.collapse', toggleChevron);
$('#accordion').on('shown.bs.collapse', toggleChevron);

        $(window).load(function() { // makes sure the whole site is loaded
            $('#status-preloder').fadeOut(); // will first fade out the loading animation
            $('#preloader').fadeOut('normal'); // will fade out the white DIV that covers the website.
            $('body').css({'overflow':'visible'});
        })




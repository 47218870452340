
$(".scale-transition").hover(function(eIn) {
    $(this).transition({
        opacity: 0.5,
        scale: 1.1
    });

    $(this)
        .children('span')
        .removeClass('hidden')
}, 
function(eOut) {

    $(this).transition({
        opacity: 1,
        scale: 1.0        
    });

    $(this)
        .children('span')
        .addClass('hidden')
});


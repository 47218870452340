Dropzone.options.profileDropzone = {
    paramName: "images",
    maxFiles: 1,
    acceptedFiles: 'image/*',
    dictDefaultMessage: '<i class="glyphicon glyphicon-camera"></i> Your image',                           
    complete: function() {
        location.reload();
    },
};    

